// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conferma-js": () => import("./../../src/pages/conferma.js" /* webpackChunkName: "component---src-pages-conferma-js" */),
  "component---src-pages-contatti-js": () => import("./../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-guida-js": () => import("./../../src/pages/guida.js" /* webpackChunkName: "component---src-pages-guida-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questionario-js": () => import("./../../src/pages/questionario.js" /* webpackChunkName: "component---src-pages-questionario-js" */)
}

